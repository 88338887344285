import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-68b2760d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "SysTitleList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef"
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("系统标题：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[0] || (_cache[0] = e => _ctx.pageList.queryParam.title = _ctx.valid(e)),
                  placeholder: "请输入系统标题进行模糊匹配查询...",
                  modelValue: _ctx.pageList.queryParam.title,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.title) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("机构：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_cascader, {
                  ref: el=>_ctx.refMap.set('corpId',el),
                  modelValue: _ctx.pageList.queryParam.corpId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.corpId) = $event)),
                  style: {"width":"100%"},
                  placeholder: "请选择机构",
                  options: _ctx.cascaderData({comboId:'corpId'})
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("说明：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[3] || (_cache[3] = e => _ctx.pageList.queryParam.desc = _ctx.valid(e)),
                  placeholder: "请输入说明进行模糊匹配查询...",
                  modelValue: _ctx.pageList.queryParam.desc,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageList.queryParam.desc) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_CORP_ID",
          label: "机构"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_TITLE",
          label: "系统标题"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_DESC",
          label: "说明"
        })
      ]),
      _: 1
    }, 8, ["pageList"])
  ]))
}